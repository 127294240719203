import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import asyncComponent from "./components/AsyncComponent";

// Components
import Header from './components/header/Header';
import CookiesConfirm from './components/other/CookiesConfirm';

// Stylesheets
import './stylesheets/bootstrap.min.css';
import './stylesheets/loader.css';
import './stylesheets/main.css';
import './stylesheets/responsive.css';
import './stylesheets/contrast.css';
//
import { router_basename } from './api/API';
// import PaginationTest from "./paginationTest";

export const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL
});


// Pages
const Page404 = asyncComponent (() => import( "./pages/404" ));
const SearchPage = asyncComponent (() => import( "./pages/SearchPage" ));
const Regulations = asyncComponent (() => import( "./pages/Regulations" ));
const PrivacyPolicy = asyncComponent (() => import( "./pages/PrivacyPolicy" ));
const AvailabilityDeclaration = asyncComponent (() => import( "./pages/AvailabilityDeclaration" ));

const Main = asyncComponent (() => import( './pages/Main' ));
const About = asyncComponent (() => import( './pages/About' ));
const Join = asyncComponent (() => import( './pages/Join' ));
const Partners = asyncComponent (() => import( './pages/Partners' ));
const FAQ = asyncComponent (() => import( './pages/FAQ' ));
const Contact = asyncComponent (() => import( './pages/Contact' ));
const News = asyncComponent (() => import( './pages/News' ));
const Offers = asyncComponent (() => import( './pages/Offers' ));
const Events = asyncComponent (() => import( './pages/Events' ));
const Registration = asyncComponent (() => import( './pages/Registration' ));
const VideoChat = asyncComponent (() => import( './pages/VideoChat' ));

const SinglePartner = asyncComponent (() => import( './pages/SinglePartner' ));
const SingleNew = asyncComponent (() => import( './pages/SingleNew' ));
const SingleOffer = asyncComponent (() => import( './pages/SingleOffer' ));
const SingleEvent = asyncComponent (() => import( './pages/SingleEvent' ));

class App extends Component{

	constructor(props){
		super(props);
		this.state = {}
	};

	componentDidMount(){
		if( localStorage.getItem('contrast_version') === '1' ) document.body.classList.add('contrast');
	}

	render(){
		return (		
			<>
				<Router basename={router_basename}>

					<CookiesConfirm />
					<Header />

					<Switch>

						<Route exact path="/" component={ Main } />
						<Route exact path={`/about`} component={ About } />
						<Route exact path={`/join`} component={ Join } />
						<Route exact path={`/partners`} component={ Partners } />
						<Route exact path={`/faq`} component={ FAQ } />
						<Route exact path={`/contact`} component={ Contact } />
						<Route exact path={`/news`} component={ News } />
						<Route exact path={`/offers`} component={ Offers } />
						<Route exact path={`/events`} component={ Events } />
						<Route exact path={`/registration`} component={ Registration } />
						<Route exact path={`/video-chat`} component={ VideoChat } />

						<Route path={`/partners/:id`} component={ SinglePartner } />
						<Route path={`/news/:id`} component={ SingleNew } />
						<Route path={`/offers/:id`} component={ SingleOffer } />
						<Route path={`/events/:id`} component={ SingleEvent } />

						<Route exact path="/search" component={ SearchPage } />
						<Route exact path="/regulations" component={ Regulations } />
						<Route exact path="/privacy-policy" component={ PrivacyPolicy } />
						<Route exact path="/availability-declaration" component={ AvailabilityDeclaration } />
						<Route exact path="/404" component={ Page404 } />
						<Route component={Page404 } />
						
					</Switch>
					
				</Router>
			</>
		)
	}
}

export default App;
