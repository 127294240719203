import React, { Component } from 'react';
import { withRouter } from "react-router";

class HeaderForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			search_str: ""
		}
	}

	submit = (e) => {
		e.preventDefault();
		const { search_str } = this.state;
		this.props.history.push('/search', { search_str });
		this.setState({ search_str: "" });
	}

	onChange = (e) => this.setState({ [e.target.name]: e.target.value });

	render(){
		return (
			<form className="" method="GET" onSubmit={ this.submit } >
				<input type="search" placeholder="Szukaj" name="search_str" value={ this.state.search_str } onChange={ this.onChange } />
				<button type="submit"><em className="ci search-icon"></em> <span className="sr-only">Szukaj</span></button>          
			</form>
		)
	}
}

export default withRouter((HeaderForm));
