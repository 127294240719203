import React from 'react';

import HeaderTopBar from './HeaderTopBar';
import HeaderMainNav from './HeaderMainNav';

export default function Header(props){
	return(
		<>
			<header className="ckm-header">
				<HeaderTopBar />
				<HeaderMainNav />
			</header>
		</>
	)
};