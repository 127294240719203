import axios from "axios";
import { getMobileDeviceOS, isMobile } from "./extra_functions";


const version = "PROD" // DEV PROD
const api_settings = {
	"PROD": {
		debug: 0,
		API_URL: "https://api.um.gdynia.pl",
		router_basename: "/",
		link_to_registration: "https://konto.um.gdynia.pl/registration",
		link_to_login: "https://konto.um.gdynia.pl/login"
	},

	"DEV": {
		debug: 1,
		API_URL: "https://um.s3.netcore.pl/app/",
		router_basename: "/",
		link_to_registration: "/panel/registration",
		link_to_login: "/panel/login"
	}
};

const link_to_market = 
	getMobileDeviceOS() === "ios"
	? "https://apps.apple.com/pl/app/gdynia-pl/id1254516980?l=pl"
	: "https://play.google.com/store/apps/details?id=pl.gdynia.app";

const { API_URL, debug, router_basename } = api_settings[ version ];

const link_to_registration = isMobile() ? link_to_market : api_settings[ version ].link_to_registration;
const link_to_login = isMobile() ? link_to_market : api_settings[ version ].link_to_login;


const API = axios.create({ 
	baseURL: API_URL 
});

export {
	API,
	debug,
	router_basename,
	link_to_registration,
	link_to_login
};