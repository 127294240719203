import React from 'react';

function addZeroIfNeeded(num){
	return num < 10 ? `0${+num}` :  num;
};


function removeHtmlTags(content) {
	content = content.replace(/<[^<>]+>/g, ' ');
	content = content.replace(/\s\s+/, ' ');
	return content;
};


function isOpened(hours_object) {
	if (toString.call(hours_object) === "[object Object]") {

		const today = new Date();
		const today_day = today.getDay() === 0 ? 7 : today.getDay();

		const hours = today.getHours() <= 9 ? "0" + today.getHours() : today.getHours();
		const minutes = today.getMinutes() <= 9 ? "0" + today.getMinutes() : today.getMinutes();
		const now_time = hours + ":" + minutes;

		const todayFrom = today_day + '_from';
		const todayTo = today_day + '_to';

		let state;
		const info = {
			"opened": {
				color: '#28bb96',
				label: 'OTWARTE TERAZ'
			},
			"closed": {
				color: '#ae3868',
				label: 'TERAZ ZAMKNIĘTE'
			}
		};


		if (now_time && hours_object[todayFrom] && hours_object[todayTo]) {

			const current_time = +new Date(`2019-01-01 ${now_time}`);
			const time_from = +new Date(`2019-01-01 ${hours_object[todayFrom]}`);
			let time_to = +new Date(`2019-01-01 ${hours_object[todayTo]}`);

			// If partner works after 00:00 (plus 24 hours)
			if( time_from > time_to ) time_to = time_to + (24 * 60 * 60 * 1000);

			state = (current_time >= time_from && current_time < time_to) ? "opened" : "closed";
			info[state].to = "OTWARTE DO " + hours_object[todayTo];
			info[state].today = {
				from: hours_object[todayFrom],
				to: hours_object[todayTo]
			};

			return info[state];
		}

	} else {
		throw new Error("Hours should be an object!");
	}
}


function getHoursJSX(hours_object){
	if (toString.call(hours_object) === "[object Object]") {
		
		const hours_jsx = [];
		const day_names = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];

		for(let i = 0; i < 8; i++){
			const from = hours_object[`${i}_from`];
			const to =  hours_object[`${i}_to`];
			
			if(from && to) {
				hours_jsx.push(
					<div key={i}>
						<strong> {day_names[i-1]} </strong> {from} : {to}
					</div>
				);
			} else if (i === 6 || i === 7)(
				hours_jsx.push(
					<div key={i}>
						<strong> {day_names[i-1]} </strong> nieczynne
					</div>
				)
			)
		}

		return hours_jsx;

	} else throw new Error("Hours should be an object!");
};


function getMapBounds(items) {
	if( Array.isArray(items) && items.length > 0 ) {
		let min_lat, max_lat = 0;
		let min_lng, max_lng = 0;

		for (let i = 0; i < items.length; i++) {
			const {lat, lng} = items[i];

			if(lat && lng) {
				if(!min_lat) min_lat = lat;
				if(!min_lng) min_lng = lng;

				if(+lat < min_lat) min_lat = lat;
				if(+lat > max_lat) max_lat = lat;

				if(+lng < min_lng) min_lng = lng;
				if(+lng > max_lng) max_lng = lng;
			}
		}

		return [
			[+min_lat, +min_lng],
			[+max_lat, +max_lng]
		];
	}
};


function isMobile (){
	return window.navigator.userAgent.toLowerCase().includes("mobi");
};


function getMobileDeviceOS(){
	const user_agent =  window.navigator.userAgent.toLowerCase();
	return user_agent.includes("mac os") 
		? "ios"
		: user_agent.includes("android")
			? "android"
				: undefined;
};

export {
	addZeroIfNeeded,
	removeHtmlTags,
	isOpened,
	getMapBounds,
	getHoursJSX,
	isMobile,
	getMobileDeviceOS
};