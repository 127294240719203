import React from 'react';
import { Link } from "react-router-dom";
import cookie_icon from "../../svg/cookies.svg";
import "../../stylesheets/cookies-confirm.scss";

const cookie_id = "8123487g";
const cookie_key = "gdynia_guest_cookie";
const cookie_value = `${cookie_key}=${cookie_id}`;


const hideCookies = (e) => {
	e.preventDefault();
	document.cookie = cookie_value;

	const confirm_el = document.getElementById(cookie_id);
	confirm_el.parentElement.removeChild(confirm_el);
};


const isCookiesVisible = () => {
	const all_cookies = document.cookie.split(";");
	const filtered = all_cookies.filter( item => (item.trim().search(cookie_key) === 0));

	return !(filtered[0] && filtered[0].trim() === cookie_value);
};


const CookiesConfirm = () => {
	return isCookiesVisible()
	? (
		<div className="cookies-confirm" id={ cookie_id }>
			<img src={ cookie_icon } alt="cookie icon" />
	
			<div>
				Droga Użytkowniczko, Drogi Użytkowniku, realizując rozporządzenie Parlamentu Europejskiego i Rady Unii Europejskiej 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (tzw. „RODO”) uprzejmie informujemy, że w celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies. Korzystanie z naszego serwisu oznacza, że będą one zamieszczane w Państwa urządzeniu. Szczegółowe zapisy znajdziesz w 
				<Link to="/privacy-policy"> polityce prywatności </Link> serwisu.
			</div>
	
			<button className="btn-turquoise" onClick={ hideCookies }> AKCEPTUJĘ </button>
		</div>
	)
	: null
}

CookiesConfirm.propTypes = { }

export default CookiesConfirm;