import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const nav_items = [
	{
		title: 'Home',
		path: `/`
	},
	{
		title: 'Aktualności',
		path: `/news`
	},
	{
		title: 'Wydarzenia',
		path: `/events`
	},
	{
		title: 'Partnerzy',
		path: `/partners`
	},
	{
		title: 'Oferta',
		path: `/offers`
	},
	{
		title: 'Zostań partnerem',
		path: `/join`
	},
	{
		title: 'O karcie',
		path: `/about`
	},
	{
		title: 'Pytania i odpowiedzi',
		path: `/faq`
	},
	{
		title: 'Kontakt',
		path: `/contact`
	}
];
//Home, Aktualności,Wydarzenia, Partnerzy, Oferta, Zostań Partnerem, O karcie, Pytanie i odpowiedzi, Kontakt

class HeaderMainNav extends Component {

	constructor(props){
		super(props);

		this.state = {
			route_changes_counter: 0,
			mobile_open: false
		}
	};

	componentDidMount(){
		window.addEventListener("resize", this.bodyOverflowed);
	}

	componentWillUnmount(){
		window.removeEventListener("resize", this.bodyOverflowed);
	}

	bodyOverflowed = () =>{
		if(this.state.mobile_open) {
			document.body.style.overflow = "";
			document.body.classList.remove("menu-open");
		}
		
		this.setState({mobile_open: false});
	}

	toggleMobileMenu = (e) => {
		e.preventDefault();

		const mobile_open = !this.state.mobile_open;
		mobile_open && window.innerWidth <= 600
			? document.body.style.overflow = "hidden"
			: document.body.style.overflow = "";
		
		mobile_open
			? document.body.classList.add("menu-open")	
			: document.body.classList.remove("menu-open");

		this.setState({mobile_open: mobile_open});
	}

	
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo({top: 0});
			document.body.classList.remove("menu-open");
			document.body.style.overflow = "";
			this.setState(state => ({route_changes_counter: state.route_changes_counter + 1, mobile_open: false}));
		}
  }

  render(){

		const menu_class = this.state.mobile_open ? "open" : "";
		
		return (
			<div className="ckm-main-nav">
				<div className="container ckm-container">
					<Link className="logo" to="/" title="Przejdź do strony głównej">
						<em className="logo-icon"></em>
						<span> Karta <br /> Mieszkańca</span>
					</Link>

					<nav className="ckm-main-menu">
						<button id="showMobile" onClick={this.toggleMobileMenu}>
								<span className="bar"></span>
								<span className="bar"></span>
								<span className="bar"></span>
								<span className="sr-only">Pokaż/ukryj menu</span>
						</button>

						{nav_items && nav_items.length > 0 &&
							<ul className={menu_class}>
							{nav_items.map((item, index) => {
								const {title, path} = item;
								const current_path = window.location.pathname;

								//console.log(path, current_path);
								
								const reg = new RegExp(path, 'gi');
								const active = 
									(path === current_path) 
										? 'active'
										: path !== "/" && reg.test(current_path) 
											? 'active' : '';
								
								return (
									<li key={index}>
										<Link to={path} className={active} title="Przejdź do strony"> {title} </Link>
									</li>
								)
							})}
							</ul>
						}
					</nav>
				</div>
			</div>
		)
	}
}

export default withRouter(HeaderMainNav);