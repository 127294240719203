import React, { Component } from 'react';
import HeaderForm from './HeaderForm';

import { ReactComponent as InfoSvg } from "../../svg/info.svg";
import {link_to_registration, link_to_login} from "../../api/API";

const list = [
	{
		class_name: 'glob',
		label: 'Nazwa podstrony',
		href: 'https://nasze.miasto.gdynia.pl/cu/'
	},
	{
		class_name: 'bip',
		label: 'Biuletyn informacji publicznej',
		href: 'https://www.gdynia.pl/bip'
	},
	{
		class_name: 'gck',
		label: 'Gdynia Kulturalna',
		href: 'https://www.gdynia.pl/aplikacja/'
	}
];

function changeFontSize (e) {
	e.preventDefault();

	let
		target,
		body = document.body,
		fontSize = parseInt(window.getComputedStyle(body).fontSize.replace("px", "")),
		fontAction;

	target = e.target.tagName === 'BUTTON' ? e.target : e.target.parentElement;
	if( target.tagName === 'BUTTON' ) {

		fontAction = target.getAttribute('data-font');

		if( fontAction === "less" && fontSize > 10 ) fontSize -= 1;
		if( fontAction === "more" && fontSize < 18 ) fontSize += 1;
		if( fontAction === "default") fontSize = 14;

		fontSize += "px";
		body.style.fontSize = fontSize;
	}
};

function contrastSwitch (e) {
	e.preventDefault();

	const key = 'contrast_version';

	if(localStorage.getItem(key) === '1') {
		localStorage.removeItem(key);
		document.body.classList.remove('contrast');
	} else {
		localStorage.setItem(key, '1');
		document.body.classList.add('contrast');
	}
};


const config_help_center_script = {
	IWVAscriptCodeUrl: 'https:\/\/s3-eu-west-1.amazonaws.com\/inteliwise-client\/saas\/embed\/v_1.5\/5b7c4a6184c860b649f1ed022d84c80534d82d11d704261a0da2d225a361e62c/92b94519267cc7dfa01b27925f4de15e',
	type: 'text/javascript', 
	id: 'inteliwise-module'
}

export default class HeaderTopBar extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			show_help_center: false
		}
	}

	componentDidMount(){
		this.loadHelpCenter();
	}

	loadHelpCenter = () => {
		const scriptIW = document.createElement('script');
		scriptIW.type= config_help_center_script.type;
		scriptIW.src = config_help_center_script.IWVAscriptCodeUrl;
		scriptIW.id  = config_help_center_script.id;
			
		document.head.appendChild(scriptIW);
	}

	toggleHelpCenter = (e) => {
		e.preventDefault();
		!this.state.show_help_center 
			? document.body.style.overflow = "hidden"
			: document.body.style.overflow = "";  
			
		this.setState(state => ({show_help_center: !state.show_help_center}));
	}

	render () {

		const icon_after_class = 
			!this.state.show_help_center 
			? `ci ci-angle-bottom` 
			: `ci ci-angle-up`;
		
		return (
			<>
				<div id="inteliwise-agent" className={this.state.show_help_center ? "iwhidden" : ""}> </div>

				<div className="ckm-top-bar">
					<div className="container ckm-container">
						<a href="#" title="Przejdź do podstrony" onClick={this.toggleHelpCenter} className="ckm-online iw-style" id="button-inteliwise">
								<InfoSvg />
								<span>Urzędnik online</span>
								<em className={icon_after_class}></em>
							</a>

						{list && list.length > 0 &&
							<ul> 
								{
									list.map((item, index) => {
										const {class_name, label, href} = item;
										return(
											<li key={index} className={class_name}>
												<a href={href} target="_blank" title="Przejdź do podstrony">
													<span> {label} </span>
												</a>
											</li>
										)
									})
								}
							</ul>	
						}	

					
						<div className="ckm-helpers">
							<div className="d-flex align-items-center">

								<HeaderForm />	

								<a className="search-mobile" href="#" title="Pokaż wyszukiwarkę"><img src="/img/icons/search-icon.png" alt="ikona lupy" /><span className="sr-only">Pokaż wyszukiwarkę</span></a>

								<div className="ckm-adjust">
									{/* <a href="" title="Kliknij żeby odsłuchać" className="listen">
										<span className="sr-only">Odsłuchaj</span>
									</a> */}
									<a onClick={contrastSwitch} href="#" title="Włącz/wyłącz tryb kontrastowy" className="adjust">
										<span className="sr-only">Tryb kontrastowy</span>
									</a>                    		
								</div>	

								<div className="ckm-font-size">
									<button onClick={changeFontSize} data-font="more">
										<i className="fa fa-plus"></i>
										<span className="sr-only">Powiększ czcionkę</span>
									</button>
									<button onClick={changeFontSize} data-font="default" className="font-default">A</button>
									<button onClick={changeFontSize} data-font="less">
										<i className="fa fa-minus"></i>
										<span className="sr-only">Zmniejsz czcionkę czcionkę</span>
									</button>	
								</div>
							</div>
						</div>
						
						<a href={link_to_login} target="_blank" className="ckm-user user-login" title="Zaloguj">
							<span>Login</span> 
						</a>
						
						<a href={link_to_registration} target="_blank" className="ckm-user user-register" title="Zarejestruj się">
							<span>Rejestracja</span>
						</a>
					</div>
				</div>
			</>
		)
	}
}